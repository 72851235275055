import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import TrustpilotReviews from "@pittica/gatsby-plugin-trustpilot-widget"
import Button from "../components/Button"
import { CgSpinner, CgInfo } from "react-icons/cg"
import { submitEmail } from "../components/Newsletter/submitEmailNewsletter"

const Navbar = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isRateLimited, setIsRateLimited] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(""); // Clear error when typing
    setSuccess(false);
    setIsRateLimited(false);
  };

  const handleSubscribe = async () => {
    // Reset previous errors
    setError("");
    setSuccess(false);
    setIsRateLimited(false);

    if (!email.trim()) {
        return;
    }

    // React's built-in validation will catch incorrect email format
    const emailInput = document.getElementById("newsletter-email");
    if (emailInput && !emailInput.checkValidity()) {
        return;
    }

    setIsLoading(true);

    try {
        const response = await submitEmail(email);

        // ✅ If response message is exactly "Subscription request sent successfully"
        if (response.message === "Subscription request sent successfully") {
            setSuccess(true);
            return;
        }

        if (response.error) {
            try {
                const errorData = JSON.parse(response.error);
                if (errorData.title === "Member Exists") {
                    setError("You've already subscribed! 😊");
                    return;
                }
            } catch (parseError) {
                console.error("Error parsing error response:", parseError);
            }
        }

        setError("An error occurred. Please try again later ❌");
    } catch (error) {
        console.error("Error submitting email:", error.message);
        
        if (error.message.includes("Too many submissions")) {
            setIsRateLimited(true);
        } else {
            setError("An error occurred. Please try again later ❌");
        }
    } finally {
        setIsLoading(false);
    }
};


  return (
    <Container>
      <ResponsiveContainer>
        <FooterColumn>
          <Logo src="/black_logo.png" alt="Logo" />
          <StyledText style={{ marginLeft: 10, textTransform: 'none', fontSize: 14, textAlign: 'left' }}>
            All Rights Reserved © {new Date().getFullYear()}
          </StyledText>
        </FooterColumn>
        <FooterColumn>
          <a href="https://uk.trustpilot.com/review/steelskins.co" target="_blank" rel="noopener noreferrer">
            <TrustpilotLogo src="/images/trustpilot_logo.svg" alt="TrustpilotLogo" />
          </a>
        </FooterColumn>
        <FooterColumn>
          {/* Newsletter Subscribe Section */}
          <NewsletterSection>
            <Title style={{ paddingBottom: 10, fontSize: 16 }}>Subscribe to our Newsletter</Title>
            
            <NewsletterForm onSubmit={(e) => e.preventDefault()}>
              <NewsletterInput 
                id="newsletter-email"
                type="email" 
                placeholder="Enter your email" 
                value={email} 
                onChange={handleEmailChange} 
                required
              />
              <ButtonWrapperNewsletter>
                <Button 
                  onClick={handleSubscribe} 
                  disabled={isLoading} 
                  title={error} // Shows tooltip error message if invalid
                  style={{
                    fontSize: "14px",
                    padding: "10px 18px",
                    borderRadius: "20px",
                    minWidth: "110px",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isLoading ?                         <Spinner>
                                            <CgSpinner />
                                          </Spinner> : "Subscribe"}
                </Button>
              </ButtonWrapperNewsletter>
            </NewsletterForm>

            {/* Display errors or success messages */}
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {isRateLimited && <ErrorMessage>Too many submissions. Try again later ❌</ErrorMessage>}
            {success && <SuccessMessage>Successfully subscribed! 🎉</SuccessMessage>}

            <StyledText style={{ marginTop: 15, fontSize: 10 }}>
              Stay updated with the latest news from Steel Skins! Subscribe to receive stock notifications, exclusive product launches, and the latest news for features such as contactless.
            </StyledText>
          </NewsletterSection>
        </FooterColumn>
        <AddressRow>
          <ContactUs>
            <Title>Here to help</Title>
            <StyledLink to="/process">The Process</StyledLink>
            <StyledLink to="/faq">FAQ</StyledLink>
            <StyledLink to="/contact">Contact Us</StyledLink>
            <StyledLink to="/refund-policy">Refund Policy</StyledLink>
            <StyledLink to="/terms-and-conditions">Terms and Conditions</StyledLink>
          </ContactUs>
          <Address>
            Stanmore Business & Innovation Centre
            <br />
            Howard Road, Stanmore
            <br />
            London
            <br />
            HA7 1BT
            <br />
            United Kingdom
            <br />
            support@steelskins.co
            <br />
            <br />
            Note: Please do not show up without a confirmed appointment
            <br />
            otherwise we won't be able to process your card(s).
          </Address>
        </AddressRow>
      </ResponsiveContainer>
    </Container>
  );
};

const ErrorMessage = styled.p`
  color: red;
  background-color: #f8d7da;
  font-size: 14px;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 0px;
  border-radius: 25px;
  width: 100%;
  max-width: 500px;
  text-align: center;
`;

const SuccessMessage = styled.p`
  color: green;
  background-color: #d4edda;
  font-size: 14px;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: -5px;
  border-radius: 25px;
  width: 100%;
  max-width: 500px;
  text-align: center;
`;


const NewsletterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; // Vertical alignment
  width: 100%;
  min-height: 150px; // Ensure uniform height like FooterColumn
  margin-bottom: 15px;
  
  @media only screen and (min-width: 1024px) {
  margin-right: 25px;
}

  @media only screen and (max-width: 1024px) {
    min-height: auto;
    margin-bottom: 25px;
  }

    @media only screen and (max-width: 450px) {
    padding: 0 20px; 
    transform: scale(0.90); // Shrinks entire section
  }
`;

const NewsletterForm = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.lightGrey}; 
  border-radius: 25px;
  padding: 10px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const NewsletterInput = styled.input`
  flex: 1;
  padding: 12px 15px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  background: ${props => props.theme.white};
  color: ${props => props.theme.black};
  &:focus {
    outline: none;
    box-shadow: 0 0 4px ${props => props.theme.primary};
  }
`;

const NewsletterTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: ${props => props.theme.black};
  margin-bottom: 10px;
  text-align: center;
`;


const ButtonWrapperNewsletter = styled.div`
  margin-left: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TrustpilotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 16px;
  margin-right: 20%;
  height: 110px;
  transform: scale(1.2);
  @media only screen and (max-width: 1400px) {
    margin-right: unset;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.white};
  padding: 40px 0%;
  z-index: 2;
  color: ${props => props.theme.black};
`;

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; // Center contents in the middle
  width: 100%;
  height: 100%;
  max-width: 1140px;

 @media only screen and (min-width: 1400px) {
    margin-left: 100px;
  }


  @media only screen and (max-width: 1400px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  // Ensures vertical centering
  flex: 1; // Equal width distribution
  text-align: center;
  min-height: 150px; // Ensures uniform height
  padding-right: 30px;
  padding-left: 30px;

  @media only screen and (max-width: 1024px) {
    min-height: auto; // Responsive for mobile
    margin-bottom: 20px;
    
  }
`;

// const FooterColumn = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: flex-start;
//   color: ${props => props.theme.black};
//   @media only screen and (max-width: 1400px) {
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     margin-bottom: 24px;
//     padding-bottom: 16px;
//   }

//   @media only screen and (min-width: 1024px) {
//     margin-right: 80px;
//   }
// `;

const AddressRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; // Ensures everything inside aligns to the center
  width: 100%;
  color: ${props => props.theme.black};

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
  }
`;

const Logo = styled.img`
  height: 50px;
`;

const TrustpilotLogo = styled.img`
  height: 40px;
`;

const ContactUs = styled.span`
  white-space: pre;
  display: flex;
  flex-direction: column;
  font: ${props => props.theme.light};
  color: ${props => props.theme.black};
  @media only screen and (max-width: 1400px) {
    margin-bottom: 24px;
    margin-left: 0px;
    width: 75%;
  }
  text-transform: uppercase;
`;

const Text = styled.span`
  font: ${props => props.theme.light};
  color: ${props => props.theme.black};
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  text-align: left;
`;

const StyledText = styled.span`
  text-decoration: none;
  font: ${props => props.theme.light};
  color: ${props => props.theme.black};
  opacity: 0.8;
  font-weight: 400;
  letter-spacing: 0.2px;
`;

const Title = styled.span`
  font: ${props => props.theme.bold};
  color: ${props => props.theme.black};
  font-weight: 500;
  letter-spacing: 0.6px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font: ${props => props.theme.light};
  color: ${props => props.theme.black};
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  transition: opacity ease-in-out 0.3s;
  font-weight: 400;
  letter-spacing: 0.2px;
`;

const Address = styled.div`
  white-space: pre;
  display: flex;
  flex-direction: column;
  font: ${props => props.theme.light};
  color: ${props => props.theme.black};
  margin-left: 24px;
  font-weight: 400;
  letter-spacing: 0.1px;

  @media only screen and (max-width: 1400px) {
    flex-direction: column;
    margin-bottom: 24px;
    margin-left: 0px;
    width: 75%;
  }

  .note {
    padding: 10px; /* Default padding */
    white-space: normal; /* Allow text to wrap */
    @media only screen and (max-width: 768px) {
      padding: 5px; /* Reduced padding for smaller screens */
    }
  }
`;

const MessageContainer = styled.div`
  width: 100%;
  max-width: 600px; // Same as NewsletterForm
  text-align: center;
  font-size: 14px;
  color: ${(props) => props.theme.black};
  font-weight: bold;
  background: ${(props) => props.theme.lightGrey}; // Matches input field background
  border-radius: 25px; // Matches input field
  padding: 12px 15px; // Similar padding as input field
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;


const Spinner = styled.div`
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; // Adjust the size as needed
  color: white;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default Navbar;
