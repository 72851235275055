import React from "react"
import { Helmet } from "react-helmet"
import { useSiteMetadata } from "../hooks/useSiteMetadata.js"

export const SEO = ({
  title,
  description,
  pathname,
  children,
  copyright,
  keywords,
}) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    copyright: defaultCopyright,
    keywords: defaultKeywords,
    siteUrl,
  } = useSiteMetadata()

  const seo = {
    title: title ? `${title} | ${defaultTitle}` : defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
    copyright: copyright || defaultCopyright,
    keywords: keywords || defaultKeywords,
  }

    // JSON-LD structured data
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "Steel Skins",
      "url": "https://steelskins.co",
      "logo": "https://steelskins.co/black_logo.png",
      "description":
        "Steel Skins offers premium luxury prestigious card clones such as the notable American Express Centurion card, J.P Morgan Chase reserve card & many more. Convert your current Visa/MasterCard/Amex card into one of our prestigious card clones. Free International shipping is available at checkout. Stand out when paying.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Stanmore Business & Innovation Centre, Howard Road",
        "addressLocality": "Stanmroe, London",
        "addressRegion": "MI",
        "postalCode": "HA7 1BT",
        "addressCountry": "UK",
      },
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday"
          ],
          "opens": "09:00",
          "closes": "18:00",
          "appointmentOnly": true
        }
      ],
      "sameAs": [
        "https://www.facebook.com/SteelSkinsCo/",
        "https://www.instagram.com/steelskins.co/",
      ],
    };

    return (
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        <meta name="image" content={seo.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:url" content={seo.url} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
        <meta name="twitter:creator" content={seo.twitterUsername} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:type" content="website" />
        <link rel="icon" href="/favicon.ico" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        {children}
      </Helmet>
    );  
}
