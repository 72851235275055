exports.components = {
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-exclusive-replicas-js": () => import("./../../../src/pages/exclusive-replicas.js" /* webpackChunkName: "component---src-pages-exclusive-replicas-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orders-login-js": () => import("./../../../src/pages/orders/login.js" /* webpackChunkName: "component---src-pages-orders-login-js" */),
  "component---src-pages-orders-manage-order-js": () => import("./../../../src/pages/orders/manage-order.js" /* webpackChunkName: "component---src-pages-orders-manage-order-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-replicas-js": () => import("./../../../src/pages/replicas.js" /* webpackChunkName: "component---src-pages-replicas-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

