import React, { useEffect } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import styled from "styled-components";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Cart from "./Cart/Cart";
import Basket from "./Cart/Basket";
import { LiveChat } from "./Chat";
import BannerNotice from "./BannerNotice";
import CampaignContextProvider from "../context/campaign-context";
import useZendeskWidget from './Chat/useZendeskWidget';
import useFacebookPixel from "../hooks/metaPixel"

const Layout = ({ children }) => {
  useZendeskWidget('c750ce12-087f-44f6-af27-f07b2edcab15');
  //useFacebookPixel();
  
  return (
    <>
      <script async src="https://pixel.wetracked.io/steel-skins.myshopify.com/events.js"></script>
      <script async src="https://pixel.wetracked.io/ppt.js"></script>
      <CampaignContextProvider>
        <Cart>
          <Container>
            <Background>
              <BackgroundImage
                src="/images/shape_1.png"
                style={{
                  top: "55vh",
                  right: "67vw",
                  mixBlendMode: "difference",
                }}
              />
            </Background>
            <BannerNotice />
            <Navbar />
            <Content>{children}</Content>
            <Basket />
            <Footer />
          </Container>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </Cart>
      </CampaignContextProvider>
    </>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.gradient1};
`

const Content = styled.div`
  width: 100%;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${props => props.theme.lightGrey};
`

const BackgroundImage = styled.img`
  position: absolute;
  z-index: 2;
`

export default Layout;
