// Function to submit the email to marketing provider the AWS Lambda function using fetch
export const submitEmail = async (email) => {
  try {
    const response = await fetch('https://cnhag01x3j.execute-api.us-east-2.amazonaws.com/production/tickets', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),  // Convert email into JSON format
    });

    const data = await response.json(); // Parse the JSON from the response

    if (!response.ok) {
      if (response.status === 429) {
        throw new Error('Too many submissions. Please try again later.');
      }
      return data; // Pass the full response to check for "Member Exists"
    }

    console.log("Response from Lambda:", data);
    return data;
  } catch (error) {
    console.error("Error submitting the email:", error.message);
    throw error;
  }
};
