import styled from 'styled-components';

export default styled.button`
  background: ${(props) =>props.theme.black};
  border-radius: 28px;
  border: 1px solid ${(props) => props.secondary ? props.theme.lightGrey : props.theme.black};
  font: ${(props) => props.theme.bold};
  color: ${(props) => props.theme.lightGrey};
  padding: 19px 50px;
  outline: none;
  cursor: pointer;
  transition: .2s all ease-in-out;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.6px;

  &:hover {
    background: ${(props) =>props.theme.lightGrey};
    color: ${(props) => props.theme.black};
  }
`;